<template>
  <div class="system-container fixed_container">
    <a-menu v-model="current" mode="horizontal" class="menu_group fixed_menu">
      <!-- <a-menu-item key="/system/operationLog" v-if="allRoutes.includes('operationLog')">
        <router-link :to="{ name: 'operationLog' }">操作日志</router-link>
      </a-menu-item> -->
      <!-- <a-menu-item key="/system/rights" v-if="allRoutes.includes('rights')">
        <router-link :to="{ name: 'rights' }">角色权限</router-link>
      </a-menu-item> -->
      <!-- <a-menu-item key="/system/personnel" v-if="allRoutes.includes('personnel')">
        <router-link :to="{ name: 'personnel' }">员工管理</router-link>
      </a-menu-item> -->
      <!-- <a-menu-item key="/system/errorDiagnose" v-if="allRoutes.includes('errorDiagnose')">
        <router-link :to="{ name: 'errorDiagnose' }">异常诊断</router-link>
      </a-menu-item> -->
      <!-- <a-menu-item key="/system/accountManage" v-if="allRoutes.includes('accountManage')" >
        <router-link :to="{ name: 'accountManage' }">账户管理</router-link>
      </a-menu-item> -->
      <!-- <a-menu-item key="/system/sdkManage" v-if="allRoutes.includes('sdkManage')" >
        <router-link :to="{ name: 'sdkManage' }">SDK版本管理</router-link>
      </a-menu-item> -->
    </a-menu>
    <route-view></route-view>
  </div>
</template>
<script>
import { RouteView } from '@/layouts'
import { mapState } from 'vuex'
export default {
  name: 'System',
  components: {
    RouteView
  },
  data () {
    return {
      current: ''
    }
  },
  computed: {
    ...mapState({
      // 权限路由集合
      allRoutes: (state) => state.permission.allRoutes
    })
  },
  watch: {
    $route: {
      handler (val) {
        const routes = val.matched.concat()
        this.current = [routes.pop().path]
      },
      immediate: true
    }
  },
  methods: {}
}
</script>
<style lang="less">
.system-container {
  background-color: #f7f7f7;
  .ant-calendar-range-picker-input {
    font-size: 12px;
    width: 40%;
  }
}
</style>
